import {
  addFilterParams,
  GridColumn,
  GridLoader,
  GridLoadSuccess,
  GridLoadTotalSuccess,
  GridResultLoader,
  GridResultOptions,
  gridResultsParams,
  GridRow,
  GridSort,
  GridTotalLoader,
  GridTotalsGridLoader,
  GridTotalsLoadGridSuccess,
} from "@getregistered/greg-editors";
import { fetchJson } from "../../components/railsFetch";

export const addSortParams = (params: URLSearchParams, sort: GridSort) => {
  params.append("s", sort.column);
  params.append("so", sort.order);
};

export type GridLoadSuccessResponse = Omit<GridLoadSuccess, "type">;

export const fetchGridResults = async (
  baseUrl: string,
  columns: GridColumn[],
  params: GridResultOptions,
  signal: AbortSignal
): Promise<any> => {
  const queryParams = new URLSearchParams({
    p: params.pageParams.page.toString(),
    pp: params.pageParams.perPage.toString(),
  });
  if (params.sort !== undefined) {
    addSortParams(queryParams, params.sort);
  }
  addFilterParams(queryParams, columns, params.filters);
  const url = `${baseUrl}?${queryParams.toString()}`;
  const response = await fetchJson(url, { signal });
  return await response.json();
};

export const resultLoader = <TResponse extends GridLoadSuccessResponse>(
  baseUrl: string,
  columns: GridColumn[],
  onFetchResults?: (response: TResponse) => void
): GridResultLoader => {
  return async ({ pageParams, filters, sort }, signal) => {
    const result = (await fetchGridResults(
      baseUrl,
      columns,
      { pageParams, filters, sort },
      signal
    )) as TResponse;
    onFetchResults?.(result);
    return {
      type: "success",
      totalResults: result.totalResults as number,
      rows: result.rows as GridRow[],
    } as GridLoadSuccess;
  };
};

export const totalsGridLoader = (
  baseUrl: string,
  columns: GridColumn[]
): GridTotalsGridLoader => {
  return async ({ column, filters }, signal) => {
    const params = new URLSearchParams({ column: column.key, mode: "grid" });
    addFilterParams(params, columns, filters);

    const url = `${baseUrl}?${params.toString()}`;
    const response = await fetchJson(url, { signal });
    const result = await response.json();
    return {
      type: "success",
      grid: result.grid,
      rows: result.rows,
    } as GridTotalsLoadGridSuccess;
  };
};

export const totalLoader = (
  baseUrl: string,
  columns: GridColumn[]
): GridTotalLoader => {
  return async ({ column, filters }, signal) => {
    const params = new URLSearchParams({ column: column.key, mode: "simple" });
    addFilterParams(params, columns, filters);

    const url = `${baseUrl}?${params.toString()}`;
    const response = await fetchJson(url, { signal });
    const result = await response.json();

    return {
      type: "success",
      totals: result.totals,
    } as GridLoadTotalSuccess;
  };
};

export const loadRecordsKeyGenerator = (
  resultsUrl: string,
  columns: GridColumn[]
) => {
  return (options: GridResultOptions) =>
    `${resultsUrl}?${gridResultsParams(columns, options)}`;
};

export const gridLoader = <
  TLoadRecordsResponse extends GridLoadSuccessResponse = GridLoadSuccessResponse
>(
  resultsUrl: string,
  totalsUrl: string,
  columns: GridColumn[],
  onFetchResults?: (response: TLoadRecordsResponse) => void
): GridLoader => {
  return {
    loadRecords: resultLoader<TLoadRecordsResponse>(
      resultsUrl,
      columns,
      onFetchResults
    ),
    loadRecordsKey: loadRecordsKeyGenerator(resultsUrl, columns),
    loadTotal: totalLoader(totalsUrl, columns),
    loadTotalsGrid: totalsGridLoader(totalsUrl, columns),
  };
};
