import React, { FC } from "react";
import {
  adaptRegFlowRoomBlockFromResponse,
  adaptRoomBookingResponse,
  Currency,
  DateFormats,
  gregAdminTheme,
  LocaleProvider,
  RegFlowApiClient,
  RegFlowApiContext,
  RegFlowResponseRoomBlock,
  ResponseConfirmedRoomBooking,
  RoomBlockField,
  RoomBookingFormField,
} from "@getregistered/greg-editors";
import { ChakraProvider } from "@chakra-ui/react";
import railsFetch from "./railsFetch";
import { RoomBooking } from "@getregistered/greg-editors/dist/lib/domain/rooming/types";

export interface RoomingFormFieldProps {
  field: RoomBlockField;
  roomBlock: RegFlowResponseRoomBlock;
  originalBooking?: ResponseConfirmedRoomBooking;
  apiBaseUrl: string;
  currency: Currency;
  dateFormats: DateFormats;
}

const RoomingFormField: FC<RoomingFormFieldProps> = ({
  field,
  roomBlock,
  originalBooking,
  apiBaseUrl,
  currency,
  dateFormats,
}) => {
  const handleBookingChange = (booking?: RoomBooking) => {
    document.dispatchEvent(
      new CustomEvent("roomTypeChange", { detail: { field, booking } })
    );
  };

  return (
    <ChakraProvider theme={gregAdminTheme}>
      <LocaleProvider currency={currency} dateFormats={dateFormats}>
        <RegFlowApiContext.Provider
          value={new RegFlowApiClient(apiBaseUrl, railsFetch)}
        >
          <RoomBookingFormField
            field={field}
            roomBlock={adaptRegFlowRoomBlockFromResponse(roomBlock)}
            originalBooking={
              originalBooking !== undefined
                ? adaptRoomBookingResponse(originalBooking)
                : undefined
            }
            onChange={handleBookingChange}
          />
        </RegFlowApiContext.Provider>
      </LocaleProvider>
    </ChakraProvider>
  );
};

export default RoomingFormField;
