import { Controller } from "@hotwired/stimulus";

export class RegFormController extends Controller {
  connect() {
    jQuery(this.element)
      .linkFields()
      .addDateAndTimePickers()
      .sessionBookingCapacity();
  }
}
