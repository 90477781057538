// Setup Stripe.js and the Elements provider
import railsFetch from "../railsFetch";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Form } from "./Form";
import React from "react";

export const CheckoutForm = (props) => {
  const stripePromise = loadStripe(props.stripeKey);

  return (
    <Elements stripe={stripePromise} options={{ locale: props.locale }}>
      <Form
        outstandingBalance={props.outstandingBalance}
        i18n={props.i18n}
        onAquireToken={async (token) => {
          const response = await stripeTokenHandler(token, props.paymentUrl);
          window.location = response.location;
        }}
      />
    </Elements>
  );
};

// POST the token ID to your backend.
async function stripeTokenHandler(token, url) {
  const response = await railsFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ token: token.id }),
  });

  return response.json();
}
