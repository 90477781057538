import React, { FC } from "react";
import { ReadOnlyGrid, ReadOnlyGridProps } from "./ReadOnlyGrid";
import {
  RowEditComponent,
  RowEditComponentProps,
} from "@getregistered/greg-editors/dist/lib/domain/grid/types";
import { LeadEditModalContent } from "@getregistered/greg-editors/";
import railsFetch from "../railsFetch";
import { Lead } from "@getregistered/greg-editors/dist/lib/greg-scanning/LeadScanner/types";
import { ID } from "@getregistered/greg-editors/dist/lib/domain/types";

export interface LeadScanGridProps extends ReadOnlyGridProps {
  leadsUrl: string;
}

export interface LeadEditorProps extends RowEditComponentProps {
  leadsUrl: string;
}

const LeadEditor: FC<LeadEditorProps> = ({
  row,
  leadsUrl,
  onSave,
  onCancel,
}) => {
  const lead = {
    id: row.id,
    firstName: row["attendees:first_name"],
    lastName: row["attendees:last_name"],
    email: row["attendees:email"],
    notes: row["lead_retrieval_lead_scans:notes"],
  } as Lead;

  const handleSave = async (id: ID, notes: string) => {
    const updateLeadUrl = `${leadsUrl}/${id}`;
    await railsFetch(updateLeadUrl, {
      method: "patch",
      body: JSON.stringify({ lead_scan: { notes } }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    onSave();
    return;
  };

  return (
    <LeadEditModalContent lead={lead} onSave={handleSave} onCancel={onCancel} />
  );
};

const createRowEditor =
  (leadsUrl: string): RowEditComponent =>
  (props) =>
    <LeadEditor {...props} leadsUrl={leadsUrl} />;
export const LeadScanGrid: FC<LeadScanGridProps> = ({ leadsUrl, ...props }) => {
  return <ReadOnlyGrid {...props} RowEditor={createRowEditor(leadsUrl)} />;
};
