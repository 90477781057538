import {
  addFilterParams,
  Grid,
  GridActionLink,
} from "@getregistered/greg-editors";
import { addSortParams } from "./resultLoader";

export const downloadUrl = (baseUrl: string, grid: Grid) => {
  const params = new URLSearchParams();
  if (grid.sort !== undefined) {
    addSortParams(params, grid.sort);
  }
  if (grid.filters !== undefined) {
    addFilterParams(params, grid.columns, grid.filters);
  }
  const query = params.toString();
  if (query === "") {
    return baseUrl;
  } else {
    return `${baseUrl}?${query}`;
  }
};

export const downloadActionLink = (
  title: string,
  baseUrl: string
): GridActionLink => ({
  title,
  onClick: (grid) => (window.location.href = downloadUrl(baseUrl, grid)),
});
