import React from "react";
import { ReactController } from "../react_controller";

import { ReadOnlyGrid } from "../../components/grids";

export class ReadOnlyGridController extends ReactController {
  connect() {
    this.root.render(<ReadOnlyGrid {...this.propsValue} />);
  }
}
