import React, { FC } from "react";
import { Grid } from "@getregistered/greg-editors";
import { gridLoader } from "../../domain/grid/resultLoader";
import {
  DownloadLink,
  PaginatedApplicationGrid,
} from "./PaginatedApplicationGrid";
import { renderCheckinCell } from "./CheckinCell/renderCheckinCell";
import { DEFAULT_PER_PAGE, DEFAULT_PER_PAGE_STORAGE_KEY } from "./constants";
import { RowEditComponent } from "@getregistered/greg-editors/dist/lib/domain/grid/types";

export interface ReadOnlyGridProps {
  grid: Grid;
  resultsUrl: string;
  totalsUrl: string;
  downloadLinks?: DownloadLink[];
  RowEditor?: RowEditComponent;
}

export const ReadOnlyGrid: FC<ReadOnlyGridProps> = ({
  grid,
  resultsUrl,
  totalsUrl,
  downloadLinks,
  RowEditor,
}) => {
  const loader = gridLoader(resultsUrl, totalsUrl, grid.columns);

  return (
    <PaginatedApplicationGrid
      grid={grid}
      loader={loader}
      defaultPerPage={DEFAULT_PER_PAGE}
      perPageStorageKey={DEFAULT_PER_PAGE_STORAGE_KEY}
      page={1}
      downloadLinks={downloadLinks}
      RowEditor={RowEditor}
      renderCell={renderCheckinCell}
    />
  );
};
