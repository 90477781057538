import React, { FC } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import {
  gregAdminTheme,
  PaginatedGrid,
  PaginatedGridProps,
} from "@getregistered/greg-editors";

import { downloadActionLink } from "../../domain/grid/downloadActionLink";

export interface DownloadLink {
  title: string;
  baseUrl: string;
}

export const PaginatedApplicationGrid: FC<
  PaginatedGridProps & { downloadLinks?: DownloadLink[] }
> = (props) => (
  <ChakraProvider theme={gregAdminTheme} resetCSS={false}>
    <PaginatedGrid
      {...props}
      actionLinks={props.downloadLinks?.map(({ title, baseUrl }) =>
        downloadActionLink(title, baseUrl)
      )}
    />
  </ChakraProvider>
);
