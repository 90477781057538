// Fix "Failed to execute 'postMessage' on 'DOMWindow'" Stripe / Turbo compat
// issuehttps://github.com/stripe/react-stripe-js/issues/188
// https://github.com/hotwired/turbo/issues/270#issuecomment-1068130327
// persist initial stripe iFrame DOM Object across turbo AJAX page requests
let stripeIFrameQuery = 'iframe[src^="https://js.stripe.com"]';
document.addEventListener("turbo:before-render", function (event) {
  const stripeIFrame = document.querySelector(stripeIFrameQuery);
  const newStripeIFrame = event.detail.newBody.querySelector(stripeIFrameQuery);
  if (stripeIFrame && !newStripeIFrame) {
    event.detail.newBody.appendChild(stripeIFrame);
  }
});
